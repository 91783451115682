<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Address
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Annual income from any employments: £ *"
            v-model="fields.annual_income"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('annual_income')"
            :error-messages="errors['annual_income']"
          ></v-text-field>

          <v-text-field
            label="Details of any bursary payments or part time employment income:"
            v-model="fields.other_income"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('other_income')"
            :error-messages="errors['other_income']"
          ></v-text-field>

          <v-text-field
            label="Savings: £"
            v-model="fields.savings"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('savings')"
            :error-messages="errors['savings']"
          ></v-text-field>

          <v-select
            label="Current living status *"
            v-model="fields.current_living_status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_living_status')"
            :error-messages="errors['current_living_status']"
          >
          </v-select>
          <v-switch
            v-model="fields.any_pets"
            :error="errors.hasOwnProperty('any_pets')"
            :error-messages="errors['any_pets']"
            class="mt-1"
            label="Do you have any pets?"
          ></v-switch>

          <v-text-field
            label="Additional information about your pets"
            v-model="fields.additional_pet_info"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('additional_pet_info')"
            :error-messages="errors['additional_pet_info']"
          ></v-text-field>

          <v-switch
            v-model="fields.any_dependants"
            :error="errors.hasOwnProperty('any_dependants')"
            :error-messages="errors['any_dependants']"
            class="mt-1"
            label="Do you have and dependants (under 18 who will be living with you)?"
          ></v-switch>

          <v-text-field
            label="Additional information about your dependants"
            v-model="fields.additional_dependants_info"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('additional_dependants_info')"
            :error-messages="errors['additional_dependants_info']"
          ></v-text-field>

          <v-switch
            v-model="fields.any_county_court_judgements"
            :error="errors.hasOwnProperty('any_county_court_judgements')"
            :error-messages="errors['any_county_court_judgements']"
            class="mt-1"
            label="Do you have any county court judgements, court decrees, bankruptcy, administration orders, individual voluntary arrangement or any other issues of this nature that are outstanding? If yes, please add full details of dates, amounts and if satisfied in the box below."
          ></v-switch>

          <v-text-field
            label="Additional information"
            v-model="fields.county_court_judgements_info"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('county_court_judgements_info')"
            :error-messages="errors['county_court_judgements_info']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      form: {},
      statuses: [
        "Currently renting",
        "Living with relatives",
        "Living with friends",
        "University halls",
        "Own property",
        "Other",
      ],
      fields: {
        form_title: "additional",
        current_living_status: null,
        any_pets: null,
        additional_pet_info: null,
        any_dependants: null,
        additional_dependants_info: null,
        any_county_court_judgements: null,
        county_court_judgements_info: null,
        annual_income: null,
        other_income: null,
        savings: null,
      },
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(form = null) {
      if (form !== null) {
        this.form = form;
        this.isEditing = true;
        this.fields.annual_income = form.annual_income;
        this.fields.other_income = form.other_income;
        this.fields.current_living_status = form.current_living_status;
        this.fields.savings = form.savings;
        this.fields.any_pets = form.any_pets;
        this.fields.additional_pet_info = form.additional_pet_info;
        this.fields.any_dependants = form.any_dependants;
        this.fields.additional_dependants_info =
          form.additional_dependants_info;
        this.fields.any_county_court_judgements =
          form.any_county_court_judgements;
        this.fields.county_court_judgements_info =
          form.county_court_judgements_info;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.formId = this.form.application_id;
      }

      this.$store
        .dispatch("easylets/applicationformsStore/saveForm", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields = {
        form_title: "additional",
        current_living_status: null,
        any_pets: null,
        additional_pet_info: null,
        any_dependants: null,
        additional_dependants_info: null,
        any_county_court_judgements: null,
        county_court_judgements_info: null,
        annual_income: null,
        other_income: null,
        savings: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
