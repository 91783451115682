<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Additional Info</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.additionalInfoDialog.open(form)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Customer's Information</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr v-if="form.tenant_type == 'student'">
              <th>Annual income from any employments: £</th>
              <td>{{ form.annual_income }}</td>
            </tr>
            <tr v-if="form.tenant_type == 'student'">
              <th>
                Details of any bursary payments or part time employment income
              </th>
              <td>{{ form.other_income }}</td>
            </tr>
            <tr v-if="form.tenant_type == 'student'">
              <th>Savings: £</th>
              <td>{{ form.savings }}</td>
            </tr>
            <tr>
              <th>Current living status</th>
              <td>
                {{ form.current_living_status }}
              </td>
            </tr>
            <tr>
              <th>Do you have any pets?</th>
              <td>
                {{ form.any_pets ? "Yes" : "No" }}
              </td>
            </tr>
            <tr>
              <th>Additional information about your pets:</th>
              <td>
                {{ form.additional_pet_info }}
              </td>
            </tr>
            <tr>
              <th>
                Do you have and dependants (under 18 who will be living with
                you)?
              </th>
              <td>
                {{ form.any_dependants ? "Yes" : "No" }}
              </td>
            </tr>
            <tr>
              <th>Additional information about your dependants:</th>
              <td>
                {{ form.additional_dependants_info }}
              </td>
            </tr>
            <tr>
              <th style="width: 25%">
                Do you have any county court judgements, court decrees,
                bankruptcy, administration orders, individual voluntary
                arrangement or any other issues of this nature that are
                outstanding? If yes, please add full details of dates, amounts
                and if satisfied in the box below.
              </th>
              <td>
                {{ form.any_county_court_judgements ? "Yes" : "No" }}
              </td>
            </tr>
            <tr>
              <th>Additional information:</th>
              <td>
                {{ form.county_court_judgements_info }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <additional-info-dialog ref="additionalInfoDialog" />
  </div>
</template>

<script>
import AdditionalInfoDialog from "./components/AdditionalInfoDialog.vue";

export default {
  components: {
    AdditionalInfoDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
